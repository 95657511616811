import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import FLAG from "../../assests/flag/255px-Flag_of_Mauritius.png"
const Footer = (): any => {
  return (
    <>
      <motion.div
        initial={{ marginLeft: "-250px", opacity: 0.5 }}
        whileInView={{ marginLeft: 0, opacity: 1 }}
        transition={{ duration: 0.4, ease: "easeInOut" }}
        className="px-4 pt-16 mx-auto md:max-w-full  md:px-24 lg:px-8 bg-slate-800 text-white"
      >
        <Discription />
        <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4 mt-4">
          <div className="sm:col-span-2">
            <a
              href="/"
              aria-label="Go home"
              title="Company"
              className="inline-flex items-center"
            >
              <svg
                className="w-8 text-deep-purple-accent-400"
                viewBox="0 0 24 24"
                stroke-linejoin="round"
                stroke-width="2"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke="currentColor"
                fill="none"
              >
                <rect x="3" y="1" width="7" height="12"></rect>
                <rect x="3" y="17" width="7" height="6"></rect>
                <rect x="14" y="1" width="7" height="6"></rect>
                <rect x="14" y="11" width="7" height="12"></rect>
              </svg>
              <span className="ml-2 text-xl font-bold tracking-wide text-orange-500 ">
                CheckPrix
              </span>
            </a>
            <div className="mt-6 lg:max-w-sm">
              <p className="text-sm text-white">
                CheckPrix is a price comparison website for all popular online
                shops in Mauritius. We do not sell products or services. We
                compare prices on different e-commerce websites in Mauritius and
                provide links to product pages on those sites.
              </p>
              {/* <p className="mt-4 text-sm text-white">
              Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
            </p> */}
            </div>
          </div>
          <div className="space-y-2 text-sm">
            <p className="text-base font-bold tracking-wide text-white">
              Contacts
            </p>
            <div className="flex">
              <p className="mr-1 text-white">Phone:</p>
              <a
                href="tel:+230 5725 1726"
                aria-label="Our phone"
                title="Our phone"
                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                +230 5725 1726
              </a>
            </div>
            <div className="flex">
              <p className="mr-1 text-white">Email:</p>
              <a
                href="mailto:checkprix.mu@gmail.com"
                aria-label="Our email"
                title="Our email"
                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                checkprix.mu@gmail.com
              </a>
            </div>
            <div className="flex">
              <p className="mr-1 text-white">Address:</p>
              <a
                href="https://www.google.com/maps"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Our address"
                title="Our address"
                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                Mauritius
              </a>
            </div>
          </div>
          <div>
            <span className="text-base font-bold tracking-wide text-white">
              Social
            </span>
            <div className="flex items-center mt-1 space-x-3">
              {/* <a
                href="/"
                className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-5 text-white"
                >
                  <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z"></path>
                </svg>
              </a> */}
              <a
                href="https://www.instagram.com/checkprix.mu"
                className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                <svg
                  viewBox="0 0 30 30"
                  fill="currentColor"
                  className="h-6 text-white"
                >
                  <circle cx="15" cy="15" r="4"></circle>
                  <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z"></path>
                </svg>
              </a>
              <a
                href="https://www.facebook.com/checkprix"
                className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-5 text-white"
                >
                  <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z"></path>
                </svg>
              </a>
            </div>
            <p className="mt-4 text-sm text-white">
              Follow us on your favrouite social media platforms to get 
              all new updates.
            </p>
          </div>
        </div>
        <Disclaimer />
        <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
          <p className="text-sm text-white">
            {`© Copyright ${new Date().getFullYear()} Checkprix Inc. All rights reserved.`}
          </p>
          <p className="text-sm text-white items-center flex gap-2">
            <span>Made with</span>
            <FontAwesomeIcon className="text-sky-400" icon={faHeart} />
            <span>in</span>
            <img
              className="h-3"
              src={FLAG}
              alt="Flag"
            />
          </p>
          <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
            <li>
              <Link
                to={"/faq"}
                className="text-sm text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                F.A.Q
              </Link>
            </li>
            <li>
              <Link
               to={"/privacy-policy"}
                className="text-sm text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
              Privacy Policy
              </Link>
            </li>
            <li>
              <Link
               to={"/term-conditions"}
                className="text-sm text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                Terms &amp; Conditions
              </Link>
            </li>
          </ul>
        </div>
      </motion.div>
    </>
  );
};

export default Footer;

const Discription = (): any => {
  return (
    <>
      <div className="flex flex-col gap-4 pb-5 text-sm break-words text-white">
        {/* <p>
        <span className="text-orange-500 font-semibold">CheckPrix</span> is a price comparison website for all popular online shops in Mauritius. We do not sell products or services. We compare prices on different e-commerce websites in Mauritius and provide links to product pages on those sites.
      </p> */}

        <p>
          We solve a critical part of your online shopping experience. People
          generally research for mobile smartphones, tablets, and laptops online
          by checking specifications, competitor products, reviews, photos, and
          comparing price of the final product on different websites and shops.
          CheckPrix brings much of that experience under one roof: our website
          enables you to view product specifications, see similar products, and
          photos, etc. More importantly, we list prices from all popular online
          shopping websites so you can find the lowest price of mobile
          smartphones, tablets, and laptops without having to go to separate
          online shopping websites!
        </p>
      </div>
    </>
  );
};

const Disclaimer = (): any => {
  return (
    <>
      <div className="flex flex-col gap-4 pb-5 text-sm break-words text-white">
        <p>
          <span className="text-orange-500 font-semibold">Disclaimer:</span>{" "}
          CheckPrix is a price comparison website and does not claim ownership
          of all trademarks, logos, product names, brands, images, links to
          external websites, price and offers and are therefore the sole
          properties of their rightful owners and holders. They are only used on
          CheckPrix for the purpose for which they are to serve or serve.
        </p>
      </div>
    </>
  );
};
